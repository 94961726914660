

.lp_body {
  background: radial-gradient(ellipse at bottom right, #ffd335 0%, #a8d1ac 100%);
  color: white;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}


.lp_body{

  .header_cta{
    position: absolute;
    top: 20vh;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    color: white;
    align-items: center;
    display: flex;
    flex-flow: column;
  }
  .header_menu{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: 10px;
  }
  .header_menu nav{
    margin-right: 20px;
  }
  .header_section {
    width: 100%;
    height: 95vh;
    background-image: url(https://cdn.pixabay.com/photo/2021/08/26/16/58/woman-6576618_960_720.jpg);
    background-size: cover;
    background-position: center;
    filter: blur(1px) brightness(0.4);
  }
  .header_logo{
    position: absolute;
    left: 40px;
    top: 5px;
  }
  .header_logo img{
    max-height: 45px;
  }

  section{
    margin: 0 auto;
  }
  .section_content{
    max-width: 960px;
    margin: 0 auto;
    width: 90%;
    display: flex;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .section_content:not(.flex_direction_row_reverse) img{
    margin-right: 40px;
  }

  .footer_text{
    padding: 5px;
  }
  div.big_green_ball{    
    width: 300px;
    height: 300px;
    padding: 70px;
    border-radius: 50%;
    background: radial-gradient(ellipse at bottom right, #6cb773 0%, #a8d1ac 90%);
    vertical-align: middle;
    color: white;
    background-size: 20px 20px;
  }
  div.big_green_ball2{    
    width: 300px;
    height: 300px;
    padding: 70px;
    border-radius: 50%;
    background: radial-gradient(ellipse at bottom right, #d9c11b 0%, #a8d1ac 60%);
    vertical-align: middle;
    color: white;
  }

  .nice_image {
    max-height: 30vh;
    height: auto;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }

  h1 {
    font-size: 3rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  p {
    margin-bottom: 2rem;
    line-height: 1.5;
  }

  a {
    display: inline-block;
    padding: 10px 25px;
    border-radius: 15px;
    text-decoration: none;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;
    // overflow: hidden;
    text-overflow: ellipsis;
    
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
  }
  nav a{
    margin-top: 5px;
  }

  a:hover {
    color: white;
    background-color: #fd5068;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }

  footer{
    padding-top: 5px;
    background-color: var(--c-green);
  }
  .footer_menu a{
    color: white;
    font-size: 12px
  }
  .footer_menu{
    width: 90%;
    margin: auto;
    padding-bottom: 10px;
  }
  .footer_menu ul{
    justify-content: center;
  }
  a.cta_button{
    color: white;
    background-color: #fd5068;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    height: 45px;
  }
  a.cta_button:hover{
    opacity: 0.8;
  }
  
  .cta_button_left{
    margin-top: 0px;
    margin-right: 0px;
    margin-left: auto;
  }
  /* MENU *//* Desktop styles */
  header {
    background-color: white;
    color: var(--c-green);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0 2rem;
  }

  .logo img {
    max-height: 50px;
    
  }

  nav ul {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
  }

  nav li {
    margin-left: 2rem;
  }


}
