.interests-page {
  .tag-count {
    margin: 8px 16px 6px;
  }

  .interests-list {
    ion-button {
      --padding-start: 12px;
      --padding-end: 12px;
      --border-width: 2px;
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
}
