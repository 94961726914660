.background {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100vw;
    height: 100vh;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 20;
}

.modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 25;
}

.modal {
    width: 350px;
    height: fit-content;
    max-width: 80vw;
    padding: 20px;
    background: white;
    color: white;
    z-index: 10;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

.random-avatar {
    display: block;
    margin-bottom: 10px;
    .avatar {
        margin: auto;
    }
}

.user-level{
    background: #FC566D;
    border-radius: 20px;
    width: max-content;
    padding-left: 20px;
    padding-right: 20px;
    margin: auto;
    margin-bottom: 20px;
    font-size: 12px;
}

.modal-content {
    padding: 0px 20px;
    font-size: 14px;
    color: black;
    text-align: center;
    font-weight: 600;
}

.modal-actions {
    bottom: 2px;
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;


    .cancelBtn {
        font-weight: 600;
        border-radius: 10px;
        border: none;
        color: white;
        background: #333333;
        width: 50%;
        height: 38px;
    }

    .deleteBtn {
        font-weight: 600;
        border-radius: 10px;
        border: none;
        color: white;
        background: #FC566D;
        width: 50%;
        height: 38px;
    }

}

.reportBtn {
    color: #FC566D;
    text-align: center;
    font-size: 12px;
    margin-top: 18px;
}