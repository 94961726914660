.matches-page {

  background: #f7f7f7 !important;

  //HEADER
  ion-header {
    display: block;

    ion-toolbar {
      height: 64px;
      --background: transparent;

      ion-buttons {
        margin: auto;
        cursor: pointer;
        height: 35px;

        .circular-button {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #F7F7F7;
          border-radius: 20px;
          width: 30px;
          height: 30px;
        }

        ion-icon {
          font-size: larger;
          color: #D3D3D3;
        }

        ion-avatar {
          width: 35px;
          height: 35px;
        }

      }


    }

  }

  .search-container {
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;


    .search-bar {
      height: 52px;
      width: 100%;
      margin: auto;
      padding: 0;

      ion-icon {
        color: #999BA3;
        padding-left: 8px;
        --ionicon-stroke-width: 60px;
      }

      input {
        border-radius: 10px;
        background: white;
        text-align: start;
        font-size: smaller;
        font-weight: bold;
        padding-left: 52px !important;
        box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
      }
    }

    .search-red-icon {
      background: #FC566D;
      position: absolute;
      right: 12px;
      top: 18px;
      padding: 2px;
      border-radius: 20px;
      font-size: x-small;
    }

  }


  .filrers-container {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 16px;
    justify-content: center;
    gap: 4%;
    cursor: pointer;

    .matches-filter {
      position: relative;
      width: 48%;
      background: white;
      border-radius: 8px;
      box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
      padding: 4px 8px;
      display: flex;
      align-items: center;

      span {
        color: #999BA3;
        font-weight: 600;
        font-size: small;
      }

      ion-icon {
        position: absolute;
        color: #b1b3bd;
        padding-left: 4px;
        --ionicon-stroke-width: 60px;
        right: 5px;
      }

    }


  }


  //CONTENT

  ion-content {

    --background: transparent;

    .border-bottom {
      border-bottom: 1px solid var(--border-color-rgb);
    }

    .matches-list {
      white-space: nowrap;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      height: 112px;

      .matches-item {
        display: inline-block;
        width: 64px;
        margin-right: 12px;
        position: relative;
        margin-bottom: 12px;

        .profile-image {
          height: 64px;
          position: relative;
          margin-bottom: 8px;
        }

        .online-status {
          background-color: var(--ion-color-success);
          position: absolute;
          right: -8px;
          top: 26px;
          width: 18px;
          height: 18px;
          border-radius: 999em;
          border: 3px solid #fff;
        }

        .like-gold {
          position: absolute;
          bottom: -8px;
          left: 50%;
          transform: translateX(-50%);
          width: 20px;
          height: 20px;
          background-color: white;
          border-radius: 50%;
          text-align: center;
          padding-top: 2px;

          ion-icon {
            color: var(--ion-color-gold);
          }
        }

        .scroll-item-title {
          text-align: center;
          font-size: var(--font-size-sm);
          font-weight: 700;
        }

        &:first-child {
          margin-left: 12px;
        }

        &.matches-item-likes {
          .profile-image {
            border: 2px solid var(--ion-color-gold);
            border-radius: 999em;
            padding: 2px;
            margin-bottom: 6px;
          }

          .profile-image-inner {
            overflow: hidden;
            border-radius: 999em;
            height: 56px;
            display: block;
          }


          .likes-count {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 30px;
            height: 30px;
            background-color: var(--ion-color-gold);
            color: white;
            text-align: center;
            font-size: 12px;
            font-weight: 700;
            padding-top: 6px;
            border-radius: 999em;
          }
        }
      }
    }

    .message-list {

      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-right: 20px;
      padding-top: 5px;
      gap: 10px;
      overflow: hidden;

      .message-item-text {
        position: relative;
        padding: 8px 12px;
        border-bottom: 1px solid var(--border-color-rgb);


        .user-type {
          top: 10px;
          align-items: center;
          display: flex;

          span {
            font-size: x-small;
            font-weight: 700;
            color: #999BA3;
          }

          .dot {
            margin-left: 5px;
            margin-right: 5px;
          }

          .status-online {
            color: #0CBC8B;
          }


        }

        .user-name {
          font-size: medium;
          font-weight: 600;
          color: black;
          margin-top: 5px;
        }

        .message-text {
          min-height: 17px;
          font-size: smaller;
          font-weight: 400;
          color: #999BA3;
        }

        .message-time {
          min-height: 17px;
          margin-top: 5px;
          font-size: 10px;
          font-weight: normal;
          color: #999BA3;
          text-align: right;
        }

      }
    }

    .feed-item {
      .feed-item-cover {
        position: relative;

        .background-img {
          height: 0px;
          padding-bottom: 125%;
        }
      }

      .feed-item-header {
        padding: 4px 10px;
      }

      .user-name {
        font-weight: 700;
      }

      .secondary-info {
        font-size: var(--font-size-sm);
        color: var(--ion-color-medium);
      }

      .matched-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5) 10%, rgba(40, 50, 60, 0.1) 50%);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding-bottom: 24px;
      }

      .feed-item-footer {
        position: relative;
        padding: 16px 92px 16px 16px;
      }

      .feed-item-buttons {
        position: absolute;
        right: 0;
        top: -18px;
        text-align: right;
        display: inline-block;
        padding: 0 8px;

        ion-button {
          margin: 0 4px;
        }
      }

      .profile-user-info {
        line-height: 1.4em;
        color: var(--ion-color-medium);

        .info-item {
          position: relative;
          padding-left: 24px;

          ion-icon {
            position: absolute;
            top: 4px;
            left: 4px;
          }
        }
      }

      ion-icon {
        &.color-green {
          color: var(--color-button-like);
        }

        &.color-blue {
          color: var(--color-button-star);
        }
      }
    }

    .list-header {
      padding: 16px;
    }

    .chat-card {
      height: 100%;
      background: white;
      border-radius: 10px;
      box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
      position: relative;
      z-index: 1;

      .random-avatar {
        margin-left: 10px;
      }

      .new-chat-dot {
        width: 10px;
        height: 10px;
        background: #FC566D;
        position: absolute;
        right: 30px;
        top: 15px;
        border-radius: 10px;
      }

    }


    .delete-red-container {
      display: flex;
      width: 100%;
      height: 100%;
      background: #FC566D;
      justify-content: end;
      align-items: center;
      color: white;
      border-radius: 10px;

      ion-icon {
        margin-right: 15px;
        font-size: 20px;
      }


    }

  }
}