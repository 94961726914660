.ripple-loader {
  position: relative;
  width: 96px;
  height: 96px;

  ion-avatar img {
    box-shadow: 0 2px 6px 0 rgba(112,125,134,0.14);
    border: 2px solid var(--ion-color-white);
  }

  .ripple-trigger {
    position: absolute;
    z-index: 9;
    transition: transform 250ms ease-out;
    animation: beating 2s infinite ease-out;
    &:active {
      transform: scale3d(1.2, 1.2, 1);
    }
  }

  .ripple-1, .ripple-2 {
    position: absolute;
    z-index: 1;
    top: 2px;
    left: 2px;
    width: 92px;
    height: 92px;
    border-radius: 999em;
    animation: rippling 2s infinite ease-out;
  }

  .ripple-2 {
    animation-delay: 350ms;
  }

  .ripple_color_red{
    background-color: rgba(#fd4f68, .2);
    border: solid 1px rgba(#fd4f68, .4);
  }
  .ripple_color_green{
    background-color: rgba(#a0d1bc, .2);
    border: solid 1px rgba(#a0d1bc, .4);
  }

  @keyframes beating {
    0% {
      transform: scale3d(1, 1, 1);
    }
    10% {
      transform: scale3d(1.2, 1.2, 1);
    }
    20% {
      transform: scale3d(0.95, 0.95, 1);
    }
    30% {
      transform: scale3d(1.05, 1.05, 1);
    }
    40% {
      transform: scale3d(1, 1, 1);
    }
    100% {
      transform: scale3d(1, 1, 1);
    }
  }

  @keyframes rippling {
    0% {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
    100% {
      opacity: 0;
      transform: scale3d(5, 5, 1);
    }
  }
}
