.service-card {
  padding: 5px 15px;
  background: white;
  cursor: pointer;


  //MENTOR
  .mentor-container {
    align-items: center;

    .ctiny_avatar {
      width: 35px;
      height: 35px;
    }

    .mentor-info {
      margin-left: 8px;
      position: relative;
    }

    .mentor-name {
      font-weight: 600;
      font-size: small;
      color: #333333;
      align-items: center;
      display: flex;
      gap: 5px;

      ion-icon {
        color: #367CFF;
        font-size: large;
      }
    }

    .mentor-level {
      font-weight: 500;
      font-size: x-small;
      color: #848484;
      text-align: left;
    }

    .mentor-forward-icon {
      position: absolute;
      margin: auto;
      right: 0;
      top: 0;
      bottom: 0;
      font-size: larger;
      color: #D3D3D3;
    }

    .edit-label {
      height: fit-content;
      position: absolute;
      margin: auto;
      right: 25px;
      top: 0;
      bottom: 0;
      font-size: medium;
      font-weight: 500;
    }

  }


  //SERVICE
  .service-image {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    margin-top: 10px;
  }

  .caption-title {
    font-weight: 600;
    font-size: medium;
    color: #333333;
    margin-bottom: 4px;
    text-align: left;
  }

  .caption-bottom {
    display: flex;
  }

  .caption-price {
    font-weight: 700;
    font-size: medium;
    color: #FFB458;
  }

  .caption-rating {
    margin-right: 0;
    margin-left: auto;
    width: max-content;
    align-items: center;
    display: flex;
    gap: 2px;
    font-weight: bold;
    color: #333333;
    font-size: smaller;

    ion-icon {
      color: #FFB458;
      font-size: small;
    }

    .caption-rating-count {
      color: #9C9C9C;
    }

  }

}