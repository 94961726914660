#hearts_canvas {
    position: absolute;
    /* width: 100vw; */
    height: 100vh;
    /* opacity: 1; */
    z-index: 100;
    /* aspect-ratio: 9 / 16; */
    left: 50%;
    transform: translateX(-50%);
}

#hearts_canvas.img {
    animation: shake 1s;
    animation-iteration-count: infinite;
}

@keyframes shake {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(-1deg); }
    50% { transform: rotate(0deg); }
    75% { transform: rotate(1deg); }
    100% { transform: rotate(0deg); }
}