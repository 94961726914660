.swipe-card {
  height: 100%;
  position: relative;
  border-radius: var(--swipe-card-border-radius);

  .card-caption {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0;
    right: 0;
    padding: 0;
    z-index: 5;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3) 10%, rgba(40, 50, 60, 0.6) 100%);
    color: #fff;
    border-radius: 0 0 var(--swipe-card-border-radius) var(--swipe-card-border-radius);
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
    pointer-events: none;
  }


  //TOP
  .card-top-data {
    margin-top: 40px;
    margin-right: 10%;
    margin-left: 10%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin-left: 5px;
      margin-right: 5px;
    }

    .card-user-name {
      font-size: 24px;
      font-weight: 500;
    }

    .card-user-age {
      font-size: 24px;
      font-weight: 500;
    }

  }

  //BOTTOM
  .card-bottom-data {
    width: 70%;
    margin-right: 4%;
    margin-left: 4%;
    position: absolute;
    justify-content: center;
    align-items: center;
    bottom: 90px;
  }

  .card-user-info {
    line-height: 1.4em;
    font-size: 18px;

    ion-icon {
      margin-right: 8px;
      font-size: 12px;
    }
  }

  .passion-list {
    ion-button {
      --padding-start: 12px;
      --padding-end: 12px;
      --border-width: 0;
      margin-right: 8px;
      margin-bottom: 8px;
      border-radius: 999em;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background-color: rgba(255, 255, 255, 0.5);
    }
  }


  //ACTION BUTTONS
  .card-action-buttons {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 20%;
    margin-right: 4%;
    margin-bottom: 5px;
    right: 0;
    bottom: 80px;
    align-items: end;
    gap: 10px;
    pointer-events: all;
    z-index: 100;

    ion-icon {
      color: white;
    }




    .circular-button {
      display: flex;
      width: 40px;
      height: 40px;
      background: #878787aa;
      align-items: center;
      justify-content: center;
      border-radius: 20px;

      ion-icon.button-undo {
        width: 20px;
        height: 20px;
        color: white;
      }

      ion-icon.button-info {
        width: 25px;
        height: 25px;
        color: white;
      }

    }


  }




}