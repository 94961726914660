.login-page {


    --color: #fff;
  
    .z101{
      z-index: 101;
      position: relative;
    }
    .terms_text{
      width: 80%;
      margin: auto;
    }
    &.bg-gradient {
      --background: linear-gradient(162.44deg, #9FD1BC 0%, #AFD19F 100%);
      // --background: linear-gradient(to top right, var(--color-courtli-stop1) 0%, var(--color-courtli-stop2) 100%);
    }
  
    .section-brand {
      flex: 1 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      // align-items: center;
    }
  
    .section-buttons {
      flex: 0 0 auto;
      text-align: center;
    }
  
    .logo {
      margin-top: 15vh;
    }
  
    .logo-icon {
      height: 201px;
      vertical-align: middle;
    }
    .logo-text {
      vertical-align: middle;
      font-size: 40px;
      font-weight: 700;
      letter-spacing: -2px
    }
  
    .button-clear {
      max-width: 400px;
      margin: 6px auto;
    }
  
    .social-btns-holder
    {
      ion-button
      {
        margin: 0 0 16px 0;
      }
    }
  
    ion-button
    {
      height: 4rem;
      ion-icon
      {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  