.giphy-search {
  .giphy-container {
    padding: 6px 0 0;
    height: 118px;
  }

  .giphy-loading {
    padding-top: 38px;
    width: 100%;
    text-align: center;
  }

  .no-result {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    color: rgba(0,0,0,0.12);
    padding-top: 2em;
    text-transform: uppercase;
  }

  ion-input {
    border-radius: 17px;
    --background: var(--ion-item-background);
    --padding-start: 12px;
    --padding-end: 12px;
    --padding-top: 6px;
    --padding-bottom: 8px;
  }

  .scroll-horizontal {
    .scroll-item {
      img {
        border-radius: 6px;
        margin-right: 6px;
      }
    }
  }
}
