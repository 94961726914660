
.lp_body{
  /* Mobile styles */
  @media only screen and (max-width: 768px) {

    .nice_image {
        margin-right: 0px !important;
    }
    .footer_menu li {
      margin: 0;
    }
    .header_menu nav{
      display: none;
    }

    .section_content{
      flex-direction: column;
    }
    .section_paragraph{
      margin-left: 10px;
      margin-right: 10px;
    }
    .section_paragraph h2{
      margin-top: 50px;
      margin-bottom: 10px;
    }
    .section_paragraph p{
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .section_content div{
      margin: auto;
    }
    .cta_button, .section_content a{
      margin: auto;
      margin-top: 30px;
    }
    header {
      height: auto;
      padding: 1rem 2rem;
    }
    
    .logo img {
      max-height: 40px;
    }
    
    header nav {
      display: none;
      flex-direction: column;
      align-items: center;
    }
    footer ul{
      padding-left: 0px;
    }
    
    nav.open {
      display: flex;
    }
    
    nav ul {
      flex-direction: column;
      margin: 0;
    }
    
    nav li {
      margin: 1rem 0;
    }
    
    nav a {
      font-size: 1.5rem;
    }
    
    .hamburger {
      display: block;
      position: relative;
      height: 24px;
      width: 32px;
      cursor: pointer;
    }
    
    // .hamburger span {
    //   display: block;
    //   position: absolute;
    //   height: 2px;
    //   width: 100%;
    //   background: white;
    //   border-radius: 5px;
    //   transition: all 0.3s ease-in-out;
    // }
    
    .hamburger span:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
    }
    
    .hamburger span:nth-child(3) {
      bottom: 0;
    }
    
    .hamburger.open span:nth-child(1) {
      transform: rotate(45deg);
      top: 50%;
    }
    
    .hamburger.open span:nth-child(2) {
      opacity: 0;
    }
    
    .hamburger.open span:nth-child(3) {
      transform: rotate(-45deg);
      bottom: 50%;
    }
  }
}