.random-avatar {
  display: inline-block;

  .avatar {
    display: block;
    width: 48px;
    height: 48px;
    background-position: center center;
    background-color: transparent;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 999em;

    &.avatar-xs {
      width: 24px;
      height: 24px;
    }

    &.avatar-sm {
      width: 32px;
      height: 32px;
    }

    &.avatar-md {
      width: 40px;
      height: 40px;
    }

    &.avatar-lg {
      width: 64px;
      height: 64px;
    }

    &.avatar-xl {
      width: 96px;
      height: 96px;
    }

    &.avatar-xxl {
      width: 128px;
      height: 128px;
    }
  }
}
