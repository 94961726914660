@import './fonts.scss';

:root {
  --swipe-card-border-radius: 0px;
  --card-box-shadow: 0 2px 10px 0 hsla(206, 7%, 60%, .77);
  --font-size-sm: 13px;
}

// ion-button {
//   font-weight: 700 !important;
// }

ion-app.ion-page {
  width: 100%;
}

ion-app.ion-page .ion-page:not(.dont_force_mobile_aspect_ratio) {
  max-width: 500px;
  margin: auto;
}


ion-router-outlet {
  max-width: 100%;
  margin: auto;
}

ion-toolbar {
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 2%;
  padding-right: 2%;

  ion-title {
    margin: 0;
    padding: 0;
  }


  ion-button {
    --color: #FD5068;
  }

  ion-back-button {
    --color: #FD5068;
  }

}

.toolbar-reduced {
  --min-height: 4px;
}

.toolbar-custom {
  ion-icon {
    font-size: 26px;
    padding-left: 12px;
    padding-right: 12px;
    color: var(--ion-color-medium);

    &.active {
      font-size: 36px;
      color: var(--ion-color-primary);
    }
  }

  ion-buttons img {
    margin-left: 12px;
    margin-right: 12px;
  }
}

.button-custom {
  --box-shadow: 0 2px 6px 0 rgba(112, 125, 134, 0.14);
  --border-color: rgb(186, 186, 186);
  --border-radius: 999em;
  --ion-color-contrast: var(--ion-color-medium) !important;
  font-weight: 700;

  &.button-icon {
    --padding-start: 0px;
    --padding-end: 0px;
    width: 54px;
    height: 54px;

    &.button-xs {
      width: 36px;
      height: 36px;
    }

    &.button-sm {
      width: 48px;
      height: 48px;
    }

    &.button-lg {
      width: 64px;
      height: 64px;

      ion-icon {
        font-size: 40px;
      }
    }
  }

  &.button-brand {
    --ion-color-base: linear-gradient(to top right, var(--color-courtli-stop1) 0%, var(--color-courtli-stop2) 100%) !important;
    --ion-color-contrast: white !important;
  }

  &.button-brand2 {
    // --ion-color-base: linear-gradient(to top right, var(--color-courtli-stop1) 0%, var(--color-courtli-stop2) 100%)!important;
    --ion-color-contrast: white !important;
  }

  &.text-primary {
    --ion-color-contrast: var(--ion-color-primary) !important;
  }
}

.list-custom {
  margin-bottom: 24px;

  ion-list-header {
    --background: var(--ion-color-light);
    letter-spacing: 0;
    color: var(--ion-color-medium);
    font-size: var(--font-size-sm);
    min-height: 30px;

    &.help-block {
      text-transform: none;
      font-weight: 400;
    }

    ion-label {
      margin-top: 8px;
    }
  }

  ion-item {
    --border-color: rgba(var(--ion-color-medium-rgb), 0.32);
  }

  ion-range {
    --bar-height: 2px;
  }
}

.header-custom {
  box-shadow: 0 2px 6px 0 rgba(112, 125, 134, 0.14);
}

.segment-custom {
  padding: 8px 0;
  border-bottom: 1px solid rgba(var(--ion-color-medium-rgb), 0.32);

  ion-segment-button {
    --border-radius: 0;
    --ripple-color: transparent;
    --color-checked: transparent;
    font-size: 20px;
    font-weight: 700;
    color: gray;
    flex: 1;
    text-transform: inherit;
    letter-spacing: inherit;
    min-height: 28px;

    &.segment-button-checked {
      color: #FD5068;
    }

    &:first-child {
      border-right: 1px solid rgba(var(--ion-color-medium-rgb), 0.32);
    }

    ion-label {
      position: relative;
    }


    &:hover {
      color: #FD5068 !important;
      opacity: 1;
    }

  }

  .segment-badge {
    position: absolute;
    top: 0px;
    left: 4px;
    margin-left: 100%;
    background-color: var(--ion-color-primary);
    border-radius: 999em;
    padding: 0px 4px;
    color: white;
    font-size: 10px;
    line-height: 14px;
    min-width: 15px;
  }
}

ion-toolbar .segment-custom {
  border-bottom-color: transparent;

  ion-segment-button {
    ion-label {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

/** Custom modal size for Courtli Gold **/
.custom-modal-small {
  .modal-wrapper {
    height: 480px;
    border-radius: 10px;
    margin: auto 16px;

    .ion-page {
      border-radius: 10px;
    }
  }
}

.card-border ion-slides {
  border-radius: var(--swipe-card-border-radius);
}

.icon-verified {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--ion-color-primary);
  display: inline-block;
  margin-left: 8px;
  padding: 4px;


  ion-icon {
    --ionicon-stroke-width: 72px;
    color: #fff;
  }
}



.flex {
  display: flex;
}

.flexd {
  flex-direction: column;
}


.al-start {
  align-items: flex-start;
}

.al-center {
  align-items: center;
}

.al-end {
  align-items: flex-end;
}

.jc-start {
  justify-content: flex-start;
}


.jc-center {
  justify-content: center;
}

.jc-end {
  justify-content: flex-end;
}

.jc-between {
  justify-content: space-between;
}

.jc-around {
  justify-content: space-around;
}


//TAB BAR
ion-tab-bar {
  position: absolute;
  bottom: 0;
  left: 5%;
  right: 5%;
  margin-bottom: 20px;
  border-radius: 20px;
  background-color: #eaeaea7d;
  box-shadow: 0 10px 15px rgb(0 0 0 / 20%);
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(33px);
  background-blend-mode: overlay;
  z-index: 2;
  padding: 0;
  border-color: white;
  border-style: solid;
  border-width: 1px;


  ion-tab-button {
    background: transparent;
  }

  //TODO CHANGE FILTER FOR PAGES
  .tab-icon-inactive {
    //filter: brightness(0) invert(1);
    filter: brightness(0) invert(0) !important;
    opacity: 0.4 !important;
  }

}