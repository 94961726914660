.matched-modal-page {
  .matched-container {
    height: 100%;
    position: relative;
  }

  .matched-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to top, rgba(0, 0, 0, .8) 10%, rgba(40, 50, 60, 0.5) 100%);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 20%;
  }

  .matched-title {
    margin-bottom: 40px;
  }

  .matched-input {
    color: white;

    ion-icon {
      color: var(--matched-text-color);
      font-size: 20px;
    }

    .label {
      font-style: italic;
      text-align: center;
    }

    ion-toolbar {
      border-radius: 8px;
      overflow: hidden;

      ion-input {
        margin: 0 12px;
      }
    }
  }
}
