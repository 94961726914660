.profile-page {
  --border-color-rgb: rgba(var(--ion-color-medium-rgb), 0.1);

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .border-bottom {
    border-bottom: 1px solid var(--border-color-rgb);
  }

  .profile-header {
    height: 70vh;
    min-height: 400px;
    position: relative;
  }

  .profile-info {
    padding: 16px;

    .profile-title {
      margin-bottom: 8px;
    }

    .profile-user-name {
      font-size: 32px;
      font-weight: 700;
      margin-right: 4px;
    }

    .profile-user-age {
      font-size: 24px;
    }

    .profile-user-info {
      line-height: 1.4em;
      color: var(--ion-color-medium-shade);

      .info-item {
        position: relative;
        padding-left: 20px;
        margin-bottom: 4px;

        ion-icon {
          position: absolute;
          top: 4px;
          left: 4px;
          font-size: 12px;
        }
      }
    }
  }

  .profile-intro {
    padding: 4px 16px;
    line-height: 1.4em;
    font-size: 18px;
    color: var(--ion-color-medium);
  }

  .profile-anthem {
    padding: 16px;
    line-height: 1.4em;
  }

  .section-title {
    font-weight: 700;
    margin-bottom: 12px;
    font-size: 18px;
  }

  .album-artwork {
    position: relative;

    .album-cover {
      width: 64px;
      height: 64px;
      border-radius: 4px;
    }

    .album-artwork-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      ion-button {
        width: 20px;
        height: 20px;
        font-size: 7px;
        opacity: .8;
      }
    }
  }

  .profile-share, .profile-footer {
    text-align: center;
    padding: 8px;

    ion-button {
      height: 2.5em;
    }

    .button-label {
      letter-spacing: 1px;
      line-height: 1.4em;

      div:nth-child(2) {
        font-size: 12px;
      }
    }
  }

  .profile-footer {
    margin-bottom: 140px;
  }

  .bottom-actions {
    bottom: 0;
    left: 0;
    right: 0;
    margin-inline-start: 0;
    text-align: center;
    width: 100%;
    padding-bottom: calc(16px + var(--ion-safe-area-bottom));
    background-image: linear-gradient(to top, rgba(40, 50, 60, 0.3) 0%, rgba(40, 50, 60, 0) 80%);
    opacity: 0;
    animation: fadeIn 0.5s ease-in 0.5s forwards;

    // .button-custom {
    //   margin: 0 12px;
    // }

    .button-lg ion-icon {
      font-size: 36px;
    }

    ion-button {
      &.button-dislike {
        border-color: var(--color-button-dislike);
      }
      &.button-dislike ion-icon {
        color: var(--color-button-dislike);
      }
      &.button-star ion-icon {
        color: var(--color-button-star);
      }
      &.button-like ion-icon {
        color: var(--color-button-like);
      }
    }
  }

  .passion-list {
    ion-button {
      --padding-start: 12px;
      --padding-end: 12px;
      --border-width: 2px;
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
}
