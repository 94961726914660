.settings-page {

  .cards-wrapper {
    margin-bottom: 32px;
    text-align: center;
  }

  .grid-half {
    padding-left: 8px;
    padding-right: 8px;
  }

  .card-custom {
    margin: 12px;
    background-color: var(--ion-color-white);

    &.card-custom-half {
      margin: 6px;
      margin-top: 0;
    }
  }

  .plan-title {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -1.5px;
  }

  .color-gold {
    color: var(--color-button-gold);
  }

  .color-blue {
    color: var(--color-button-star);
  }

  .color-purple {
    color: var(--color-button-boost);
  }

  .padding-reduced {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .text-sm {
    font-size: var(--font-size-sm);
  }



  //LIST 
  ion-list-header {
    padding-left: 0px;
    margin-top: 30px;

    ion-label {
      font-size: smaller;
    }
  }

  .help-block {
    color: #FC566D;
    text-align: center;
    font-weight: 600 !important;
    margin-top: 0;;
  }

  .orange-icon {
    color: orange !important;
  }


  .list-custom {

    margin-left: 20px;
    margin-right: 20px;
    background: transparent;

    ion-item {
      margin-top: 12px;
      background: transparent;
      --background: white;
      --border-radius: 10px;
      --border-width: 1px;
      --border-style: solid;
      --border-color: #F0F0F0;
      cursor: pointer;
    }

    .red-item {
      --background: #FC566D;

      ion-icon {
        color: white;
      }

      ion-note {
        color: white;
      }

    }

    .green-item {
      --background: #00BF86;

      ion-icon {
        color: white;
      }

      ion-note {
        color: white;
      }

    }

    ion-icon {
      color: #92949C;
      font-size: large;
    }

    ion-note {
      font-size: smaller;
      font-weight: 600;
    }

    .editable {
      color: #333333;
    }

    .red-circular-icon {
      background: #FC566D;
      color: white;
      border-radius: 20px;
      font-size: smaller;
      padding: 1px;
      margin-right: 12px;
    }

    .white-circular-icon {
      background: white;
      color: #FC566D !important;
      border-radius: 20px;
      font-size: smaller;
      padding: 1px;
      margin-right: 12px;
    }

    .red-icon {
      color: #FC566D;
    }

    textarea {
      color: #333333;
      font-size: smaller;
      font-weight: 600;
    }

    .note {
      color: #92949C;
      font-size: small;
      font-weight: normal;
    }


    .badges {
      display: flex;
      flex-direction: row;
      padding: 2px;
      flex-wrap: wrap;
    }

    .badge {
      width: fit-content;
      --background: white;
      --border-color: #EDEDEE;
      --border-style: solid;
      --border-width: 1px;
      --color: #333333;
      --border-radius: 20px;
      font-size: small;
      font-weight: 600;
    }

  }


}